.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bg-image-slide {
  position: relative;
  overflow: hidden;
}

.bg-image-slide::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-image: url('./img4.png'); /* Initial image */
  opacity: 1;
  transition: opacity 3s ease-in-out;
  z-index: 1;
  animation: slide 15s infinite;
}

.bg-image-slide::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  z-index: 1;
}

/* @keyframes slide {
  0% { background-image: url('./img1.png'); }
  25% { background-image: url('./img2.png'); }
  50% { background-image: url('./img3.png'); }
  75% { background-image: url('./img4.png'); }
  100% { background-image: url('./img1.png'); }
} */

@keyframes slide {
  0% { background-image: url('./img4.png'); }
  /* 25% { background-image: url('./img2.png'); } */
  /* 50% { background-image: url('./img4.png'); } */
  /* 75% { background-image: url('./img4.png'); } */
  /* 100% { background-image: url('./img3.png'); } */
}

/* Ensure container or other content is above the pseudo-element */
.bg-image-slide > * {
  position: relative;
  z-index: 2;
}


.gradient-text {
  background: linear-gradient(to right, #00f, rgb(203, 76, 41), #00f); /* Example gradient */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.zoom-effect {
  transition: transform 0.3s ease;
}

.zoom-effect:hover {
  transform: scale(1.1); /* Adjust the scale as needed */
}

.zoom-effect-icon {
  transition: transform 0.3s ease;
}

.zoom-effect-icon:hover {
  transform: scale(1.4); /* Adjust the scale as needed */
}
